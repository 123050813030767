import React, { Component } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import * as FiIcons from "react-icons/fi";
import { AiOutlineLine } from "react-icons/ai";
import {FaUser, FaUserCircle } from "react-icons/fa";
import axios from "axios";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import "./Navbar.css";

//const API_URL = "http://127.0.0.1";
import { URL } from '../../config';
const API_URL = URL;
const PORT = "3000"; 

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      email: "",
      loja: [{}],
      idLoja:[],
      messages: [],
      showModal: false,
   
    };
    this.getUserProfile = this.getUserProfile.bind(this);
    this.toggleLogout = this.toggleLogout.bind(this);
    //this.toggleModal = this.toggleModal.bind(this);
  }
  toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  toggleLogout() {
    localStorage.clear();
    //localStorage.setItem("isLoggedIn", false);
    this.props.history.push("/");
  }
  toggleModal = () => {
    this.setState({showModal: !this.state.showModal})
  }
  async componentDidMount() {
    this.getUserProfile();
    if (window.location.pathname === "/profile")
      document.body.classList.toggle("sidebar-icon-only");
     if (localStorage.perfil === "user" || localStorage.perfil === "User" || localStorage.perfil === "admin" || localStorage.perfil === "SST")  document.body.classList.toggle("sidebar-icon-only"); this.toggleOffcanvas();
    axios
      .get(
        `${API_URL}/app/dashboard?secret_token=${localStorage.getItem(
          "token"
        )}`,{
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((res) => {
        this.setState({ loja: res.data });
      });
    
    
  }

  async getUserProfile() {
    const url = `${API_URL}/user/profile?secret_token=`;
    try {
      await axios.get(url + localStorage.getItem("token"),{
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${localStorage.token}`,
        },
      }).then((response) => {
        //console.log("Retorno do servidor:", response.data.user);
        this.setState({ email: response.data.user.email });
        this.setState({ user: response.data });
        //localStorage.setItem("isLoggedIn", true);
        
      axios
      .get(
        `${API_URL}/app/alertas-message-today?email=${response.data.user.email}`,{
          headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((res) => {
        this.setState({ messages: res.data });
        //console.log("Inbox: ", res.data.length)
      });
      });
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        ////console.log("NÃO TEM PERMISSÃO");
        //localStorage.setItem("isLoggedIn", false);
        //localStorage.setItem("token","");
        //window.location = "/isafety/login";
        // <Redirect to="/isafety/login" />;
        // setError("Utilizador ou Palavra-Passe inválidos...",error.response.data.message);
      }
    }
    axios.get(`${API_URL}/app/piloto/${this.state.user.user?._id}`,{
      headers: {
        "Content-Type": "application/json",
        "authorization": `Bearer ${localStorage.token}`,
      },
    }).then((res) => {
      const user = res.data;
      this.setState({ idLoja: user });

    });
  }

  handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    //console.log(string, results);
  };

  handleOnHover = (result) => {
    // the item hovered
   // console.log(result);
  };

  handleOnSelect = (item) => {
  
  
    if (localStorage.perfil === "admin") {
      this.props.history.push(`/Loja/loja/${item._id}`);
    }
  };

  handleOnFocus = () => {
    //console.log("Focused");
  };

  formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left", color: "#335675" }}>
          {item.name}
        </span>
      </>
    );
  };

  checkNewMessages = (messages) => {
    const options = {
      day: "numeric",
      weekday: "short",
      month: "short",
      year: "numeric",
    };

  let currentDate = new Date().toLocaleDateString(
    "pt-PT",
    options
  )
    const newMessages = messages.filter((item, index) => {  
   
    return (new Date(item.date).toLocaleDateString(
                  "pt-PT",
                  options
                )=== currentDate) && !item.opened
            
  })
  return newMessages
}

  render() {
   const items = this.state.loja?.map(({ Nova_Subárea, _id }, index) => {
    return { id: index, name: Nova_Subárea, _id: _id };
  });
  
  
 

  
   
   // console.log("Items", this.state.loja);
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div
          className="text-center navbar-brand-wrapper"
          style={{ backgroundColor: "#3774a9" }}
        >
           { (localStorage.perfil === "admin" || localStorage.perfil==="SST") ? 
          
          <><Link className="navbar-brand brand-logo" to="/dashboard">
              <img
                src={require("../../assets/logo/_logo_isafety.png")}
                alt="logo"
                style={{ width: 150, height: 39, marginLeft: "-2.5rem" }} />
            </Link><Link className="navbar-brand brand-logo-mini" to="/dashboard">
                <img
                  src={require("../../assets/logo/_logo_isafety_vector.png")}
                  alt="logo"
                  style={{ width: 40, height: 39 }} />
              </Link></> : 
          <><Link className="navbar-brand brand-logo"  to={`/Loja/loja/${this.state.idLoja[0]?._id}`} >
              <img
                src={require("../../assets/logo/_logo_isafety.png")}
                alt="logo"
                style={{ width: 150, height: 39, marginLeft: "-2.5rem" }} />
            </Link><Link className="navbar-brand brand-logo-mini" >
                <img
                  src={require("../../assets/logo/_logo_isafety_vector.png")}
                  alt="logo"
                  style={{ width: 40, height: 40 }} />
              </Link></>
          
          }
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
        {localStorage.perfil === "admin" ||   localStorage.perfil === "SST"? (
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            
            <span className="mdi mdi-menu"></span>
          
          </button>
            ) : (
             ""
            )}
          <div className="search-field d-none d-md-block">
            {localStorage.perfil === "admin" ||
            localStorage.perfil === "SST" ? (
              <form className="d-flex align-items-center h-100" action="#">
                <div
                  style={{
                    backgroundColor: "#F2F3F8",
                    borderRadius: 15,
                    width: 325,
                    height: 30,
                    cursor:"pointer"
                  }}
                >
                  <ReactSearchAutocomplete
                    items={items}
                    onSearch={this.handleOnSearch}
                    onHover={this.handleOnHover}
                    onSelect={this.handleOnSelect}
                    onFocus={this.handleOnFocus}
                    formatResult={this.formatResult}
                    showIcon={true}
                    styling={{
                      backgroundColor: "#F2F3F8",
                      borderRadius: "25px",
                      height: "36px",
                      border: "none",
                      searchIconMargin: "0 0 0 16px",
                      searcIconAlign: "right",
                      iconColor: "#335675",
                      cursor: "pointer"
                    }}
                  />

                  {/*<ReactSearchAutocomplete type="text"
                  style={{ backgroundColor: "#F2F3F8", borderRadius: 15 }}
              className="form-control bg-transparent border-0" />*/}
                </div>

                <br />
                <button
                  style={{
                    backgroundColor: "#335675",
                    border: 0,
                    color: "white",
                    marginLeft: 20,
                    height: "36px",
                    width: "66px",
                    borderRadius: "36px",
                  }}
                  className="filter-btn"
                >
                  <FiIcons.FiFilter size={20} className=" fi-rotate-90" />
                </button>
              </form>
            ) : (
              ""
            )}
          </div>
          <div className="nav-item">
            <span
              className="nav-item"
              style={{ textDecoration: "none" }}
            ></span>
          </div>
          <ul className="navbar-nav navbar-nav-right">
            {localStorage.perfil === ( "admin" || "SST") ? (
              <>
                {" "}
                <button
                  style={{
                    textDecoration: "none",
                    border: 0,
                    color: "#335675",
                    height: "36px",
                    width: "36px",
                    borderRadius: "36px",
                  }}
                  className="search-item"
                  onClick={() => this.toggleModal()}
                >
                  <FiIcons.FiSearch size={20} />
                </button>
                
                <Modal
                  show={this.state.showModal}
                  animation={true}
                  dialogClassName="modal-search-mobile"
                  contentClassName="modal-search-mobile"
                  top
                  onHide={this.toggleModal}
                >
                  <Modal.Header
                    closeButton
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <h5 style={{ color: "#335675" }}>Pesquisar:</h5>
                    <br></br>
                  </Modal.Header>
                  <Modal.Body>
                    <ReactSearchAutocomplete
                      items={items}
                      onSearch={this.handleOnSearch}
                      onHover={this.handleOnHover}
                      onSelect={this.handleOnSelect}
                      onFocus={this.handleOnFocus}
                      formatResult={this.formatResult}
                      showIcon={true}
                      styling={{
                        backgroundColor: "#F2F3F8",
                        borderRadius: "25px",
                        height: "36px",
                        border: "none",
                        searchIconMargin: "0 0 0 16px",
                        searcIconAlign: "right",
                        iconColor: "#335675",
                      }}
                    />
                  </Modal.Body>
                </Modal>
                        <li
                  className="nav-item-nav-profile-d-none-d-xl-flex"
                  style={{ color: "#F2F3F8", rotate: 180, transformrotate: 45 }}
                >
                  <AiOutlineLine size={30} />
                </li>
                <li className="nav-item nav-profile nav-language">
                  <Dropdown>
                    <Dropdown.Toggle className="nav-link count-indicator hide-carret">
                      <i
                        className="mdi mdi-bell-outline"
                        style={{ color: "#184469" }}
                      ></i>
                      {this.checkNewMessages(this.state.messages).length ===
                      0 ? (
                        ""
                      ) : (
                        <span className="count-symbol bg-danger"></span>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="preview-list drop"
                      style={{ width: "auto", marginTop: "-0.5rem" }}
                    >
                      <h6
                        className="p-3 mb-0 text-white py-4"
                        style={{ background: "#184469" }}
                      >
                        {" "}
                        Notificações{" "}
                      </h6>
                      <div className="dropdown-divider"></div>

                       <Dropdown.Item className="align-items-center">
                      <Link to="/inbox" className="dropdown-link" style={{textDecoration:"none"}}>
                        <div className="col-8 link-left">Cx. Entrada
                        <span
                        style={{marginLeft:"0.5rem"}}
                            className={
                              this.checkNewMessages(this.state.messages)
                                .length === 0
                                ? " badge badge-primary"
                                : "badge badge-danger"
                            }
                          >
                            {this.checkNewMessages(this.state.messages).length}
                          </span>
                          </div>
                        <div className="col-4 link-right">
                      
                         
                          <i className="mdi mdi-email-open-outline ml-1"></i>
                        </div>
                        </Link>
                      </Dropdown.Item>
                    {/*}  {localStorage.perfil === "admin" ? (
                         <Dropdown.Item className="align-items-center d-flex">
                         <Link to="/Historico" className="dropdown-link" style={{textDecoration:"none"}}>
                           <div className="col-8 link-left">Gerir Notificações</div>
                           <div className="col-4 link-right">
                            <i className="mdi mdi-calendar"></i>
                          </div>
                          </Link>
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}*/}
                     
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              
                <>
                  <li
                    className="nav-item-nav-profile-d-none-d-xl-flex"
                    style={{
                      color: "#F2F3F8",
                      rotate: 180,
                      transformrotate: 45,
                    }}
                  >
                    <AiOutlineLine size={30} />
                  </li>
                </>
              </>
            ) : (
              <li className="nav-item nav-profile nav-language" style={{marginRight:"1rem"}}>
                  <Dropdown>
                    <Dropdown.Toggle className="nav-link count-indicator hide-carret">
                      <i
                        className="mdi mdi-bell-outline"
                        style={{ color: "#184469" }}
                      ></i>
                      {this.checkNewMessages(this.state.messages).length ===
                      0 ? (
                        ""
                      ) : (
                        <span className="count-symbol bg-danger"></span>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="preview-list drop"
                      style={{ width: "auto", marginTop: "-0.5rem" }}
                    >
                      <h6
                        className="p-3 mb-0 text-white py-4"
                        style={{ background: "#184469" }}
                      >
                        {" "}
                        Notificações{" "}
                      </h6>
                      <div className="dropdown-divider"></div>

                       <Dropdown.Item className="align-items-center">
                      <Link to="/inbox" className="dropdown-link" style={{textDecoration:"none"}}>
                        <div className="col-8 link-left">Cx. Entrada
                        <span
                        style={{marginLeft:"0.5rem"}}
                            className={
                              this.checkNewMessages(this.state.messages)
                                .length === 0
                                ? " badge badge-primary"
                                : "badge badge-danger"
                            }
                          >
                            {this.checkNewMessages(this.state.messages).length}
                          </span>
                          </div>
                        <div className="col-4 link-right">
                      
                         
                          <i className="mdi mdi-email-open-outline ml-1"></i>
                        </div>
                        </Link>
                      </Dropdown.Item>
                      {localStorage.perfil === "admin" ? (
                         <Dropdown.Item className="align-items-center d-flex">
                         <Link to="/Historico" className="dropdown-link" style={{textDecoration:"none"}}>
                           <div className="col-8 link-left">Gerir Notificações</div>
                           <div className="col-4 link-right">
                            <i className="mdi mdi-calendar"></i>
                          </div>
                          </Link>
                        </Dropdown.Item>
                      ) : (
                        ""
                      )}
                     
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                
            )}
            

            <li className="nav-item">
              <Dropdown className="drop">
                <Dropdown.Toggle className="nav-link count-indicator">
                  <div
                    className="nav-profile-img"
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "5rem",
                      marginLeft: "-1rem",
                      marginTop: "-0.5rem",
                      background: "#184469",
                      padding: "0.2rem",
                      textAlign: "center",
                      justifyContent: "center",
                      display: "inline-block",
                      paddingTop: "0.4rem",
                    }}
                  >
                    <FaUser color="white" size={20} />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="preview-list  drop"
                  style={{ width: "auto", marginTop: "-0.5rem" }}
                >
                  {/*
                  <div className="p-3 text-center bg-primary">
                
                    <img className="img-avatar img-avatar48 img-avatar-thumb" src={require("../../assets/Markers/User.png")} alt="" style={{width: "45px" , borderRadius : "5rem", marginLeft: "-1rem", marginTop: "-0.5rem"}} />
    
                    </div>
                    */}
                  <div className="p-2">
                    {/*<h5 className="dropdown-header text-uppercase pl-2 text-dark">
                      {" "}
                      User Options{" "}
                  </h5>*/}
                    {/*
                    <Dropdown.Item
                      className="dropdown-item d-flex align-items-center justify-content-between"
                      href="!#"
                      onClick={(evt) => evt.preventDefault()}
                    >
                      <span> Inbox </span>
                      <span className="p-0">
                        <span className="badge badge-primary">3</span>
                        <i className="mdi mdi-email-open-outline ml-1"></i>
                      </span>
                    </Dropdown.Item>*/}
                    <Dropdown.Item className="dropdown-item align-items-center">
                      <Link to="/profile" className="dropdown-link" style={{textDecoration:"none"}}>
                        <div className="col-10 link-left">Perfil</div>
                        <div className="col-2 link-right">
                          <i className="mdi mdi-account-outline ml-1"></i>
                        </div>
                      </Link>
                    </Dropdown.Item>
                   
          {localStorage.perfil === "user" || localStorage.perfil === "User"? (
                      <Dropdown.Item className="dropdown-item align-items-center">
                        <Link to="/Risco" className="dropdown-link" style={{textDecoration:"none"}}>
                          <div className="col-10 link-left">Histórico</div>
                          <div className="col-2 link-right">
                            <i size="5" className="mdi mdi-history"></i>
                          </div>
                        </Link>
                      </Dropdown.Item>
                    ) : (
                      ""
                    )}


                    {localStorage.perfil === "admin" ? (
                      <Dropdown.Item className="dropdown-item align-items-center">
                        <Link to="/backoffice" className="dropdown-link" style={{textDecoration:"none"}}>
                          <div className="col-10 link-left">Backoffice</div>
                          <div className="col-2 link-right">
                            <i size="5" className="mdi mdi-settings"></i>
                          </div>
                        </Link>
                      </Dropdown.Item>
                    ) : (
                      ""
                    )}
                    {/*
                    <Dropdown.Item
                      className="dropdown-item d-flex align-items-center justify-content-between"
                      href="!#"
                      onClick={(evt) => evt.preventDefault()}
                    >
                      <span> Settings </span>
                      <i className="mdi mdi-settings"></i>
                    </Dropdown.Item>
                    */}
                    {localStorage.perfil === "admin" ? (
                      <>
                        <Dropdown.Item className="dropdown-item align-items-center" >
                          <Link to="/metrics" className="dropdown-link" style={{textDecoration:"none"}}>
                            <div className="col-10 link-left">Indicadores</div>
                            <div className="col-2 link-right">
                              <i className="mdi mdi-chart-bar"></i>
                            </div>
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item className="dropdown-item align-items-center">
                          <Link to="/system" className="dropdown-link" style={{textDecoration:"none"}}>
                            <div className="col-10 link-left">Sistema</div>
                            <div className="col-2 link-right">
                              <i className="mdi mdi-cloud-check"></i>
                            </div>
                          </Link>
                        </Dropdown.Item>
                      </>
                    ) : (
                      ""
                    )}
                    <div role="separator" className="dropdown-divider"></div>
                    {/* <h5 className="dropdown-header text-uppercase  pl-2 text-dark mt-2">
                      {" "}
                      Actions{" "}
                  </h5>*/}
                    <div style={{ padding: "0rem 1.5rem" }}>
                      <p></p>
                      <span> Autenticado como: </span>
                      <i className="mdi mdi-lock ml-1"></i>
                      <p>
                        <b>{this.state.email}</b>
                      </p>
                    </div>

                    <Dropdown.Item
                      className="dropdown-item d-flex align-items-center justify-content-between"
                      href="/"
                      onClick={this.toggleLogout}
                    >
                      <span> Sair </span>
                      <i className="mdi mdi-logout ml-1"></i>
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={this.toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default withRouter(Navbar);
