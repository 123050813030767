import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import Login from './log';
import "./App.css"


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {}
   // console.log("HISTORY", this.props.history.location.pathname)
    //console.log("LOCALSTORAGE", localStorage)
  }
  render () {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar/> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar/> : '';
    let SettingsPanelComponent = !this.state.isFullPageLayout ? <SettingsPanel/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';
    
    return (this.props.history.location.pathname === "/" || !localStorage.getItem('isLoggedIn') ? <Login/> :
      <>
      
      <div className="container-scroller">
        { navbarComponent }
        <div className="container-fluid page-body-wrapper">
          { localStorage.perfil === "admin" ? sidebarComponent : localStorage.perfil==="SST" ||localStorage.perfil === "User" || localStorage.perfil === "user" ? sidebarComponent : null }
          <div className= { (localStorage.perfil === "admin" || localStorage.perfil==="SST" || localStorage.perfil === "User" || localStorage.perfil === "user")  ? "main-panel" : "col-12 main-panel-user" }>
            <div className="content-wrapper" style={{padding: "1rem 0rem"}}>
              <AppRoutes/>
              { SettingsPanelComponent }
            </div>
            { footerComponent }
          </div>
        </div>
      </div>
      </> 
    );
  }

  
}
 

export default (withRouter(App));
