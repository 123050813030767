import { useState } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import styles from "./styles.module.css";
import "./log.css"

//const API_URL = "http://193.136.194.82";
//const API_URL = "http://127.0.0.1";
import { URL } from '../config';
const API_URL = URL;


const Login = () => {
	const [data, setData] = useState({ email: "", password: "" });
	const [error, setError] = useState("");

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};
	
	//

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const url = `${API_URL}/app/login`;
			const { data: res } = await axios.post(url, data);
			//console.log("DATA LOGIN AXIOS: ", res.token);
			localStorage.setItem("token", res.token);
			
			//console.log("IS LOGIN AXIOS: ", localStorage);
			//this.props.history.push("/dashboard");
			if(res.token !== "")
			await axios
      .get(
        `${API_URL}/user/profile?secret_token=${localStorage.getItem(
          "token"
        )}`
      )
      .then((res) => {
        const client = res.data;
        ////console.log(client.user._id);
		axios
        .get(`${API_URL}/app/user/${client.user._id}`)
        .then((res) => {
			
          const user = res.data;
          //console.log("User: ", user);
		  localStorage.setItem("perfil", user.perfil);
		  localStorage.setItem("loja", user.idLoja);
		  localStorage.setItem("isLoggedIn", true);
		  localStorage.setItem("id", user._id);
		  if(user.email.includes("dhmsilva@mc.pt")) localStorage.setItem("madeira", true);
		  if(user.perfil === "admin"){
		  if (window.location.href.match('http:')) {
				window.location.href = window.location.href.replace('http', 'https');
				window.location.protocol = "https";
			
			}
		  	window.location = "/welcome";
		}
		  else window.location = `/welcome`
        });


      })
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError("Utilizador ou Palavra-Passe inválidos...",error.response.data.message);
				
			}
		}
	};

	

	return (
		<div className="login-container" >
			<div className="col-12 login-logo-wrapper">
				<img src={require('../assets/images/_logo_full_color_isafety.png')} alt="logo" style={{width:300, height:"auto", marginBottom:"1.5rem"}}/>
			</div>
				<div className="col-12 login-form-wrapper">
					<form className={styles.form_container} onSubmit={handleSubmit}>
						
						<input
							type="email"
							placeholder="Utilizador"
							name="email"
							onChange={handleChange}
							value={data.email}
							required
							className={styles.input}
						/>
						<input
							type="password"
							placeholder="Palavra-passe"
							name="password"
							onChange={handleChange}
							value={data.password}
							required
							className={styles.input}
						/>
						{error && <div className={styles.error_msg}>{error}</div>}
						<button type="submit" className={styles.green_btn} >
							ENTRAR
						</button>
					</form>
					</div>
				<div className="col-12 login-footer-wrapper">
				<img src={require('../assets/logo/_logos_isafety.png')} alt="logo" style={{width:360, height:"auto", marginBottom:"1.5rem"}}/>
				</div>
				</div>
				
		
	);
};

export default Login;