import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';

const maxInactivityDuration = 2 * 60 * 60 * 1000; // 2 hours

let inactivityTimer;

// Function to start the inactivity timer
function startInactivityTimer() {
  inactivityTimer = setTimeout(() => {
    // Clear local storage and log out the user
    localStorage.clear();
    // Redirect to the login page or perform any necessary logout actions
    window.location = "/login";
  }, maxInactivityDuration);
}

// Function to reset the inactivity timer
function resetInactivityTimer() {
  clearTimeout(inactivityTimer);
  startInactivityTimer();
}

// Attach event listeners to relevant user activity events
function attachActivityListeners() {
  document.addEventListener("click", resetInactivityTimer);
  document.addEventListener("keydown", resetInactivityTimer);
  // Add more event listeners for other relevant activity events
}

// Start the inactivity timer on application load, except on the login page
if (window.location.pathname !== "/login" && window.location.pathname !== "/") {
  startInactivityTimer();
  attachActivityListeners();
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
