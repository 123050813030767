import React, { Component } from 'react';


class Footer extends Component {
  render () {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"> Copyright  © <a href="https://www.morecolab.pt/" target="_blank" rel="noopener noreferrer"> Morecolab  </a>2022</span>
            {/*<span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Free  <a href="https://www.bootstrapdash.com/react-admin-templates/" target="_blank" rel="noopener noreferrer">  Dashboard  </a>  templates from BootstrapDash.com.   </span>*/}
          
              <span className="float-sm-center justify-content-center py-2" style={{display: "flex"}}>
          <img src={require('../../assets/logo/_logos_isafety_negative.png')} alt="logo" style={{width:300, height:"auto", marginTop:"-0.5rem"}}/>
          </span>
          </div>
          <div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;