import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect, withRouter, Outlet, Navigate} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import Spinner from '../app/shared/Spinner';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));



const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));
const Form  = lazy(() => import('./services/from_service'));
const Loja = lazy(() => import('./Loja/loja'));
const Unidade = lazy(() => import('./Unidades/unidade'));
const Historico = lazy(() => import('./historico/historico'));
const Risco = lazy(() => import('./Risco/risco'));
const Seccao = lazy(() => import('./Loja/Seccao'));
const Profile = lazy(() => import('./profile/profile'));
const Backoffice = lazy(() => import('./profile/backoffice'));
const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));
const Welcome = lazy(() => import('./welcome'));
const Inbox = lazy(() => import('./Inbox/inbox'))
const Metrics = lazy(() => import('./metrics/metrics'))
const System = lazy(() => import('./system/system'))
const HistoricoNew = lazy(() => import('./Risco/HistoricoComponent'))
const MetricsNew = lazy(() => import('./metrics/MetricsComponent'))

const Login = lazy(() => import('./log'));
const Register1 = lazy(() => import('./user-pages/Register'));

const Users = lazy(() => import('./users/users'));


class AppRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {token: localStorage.token}
    this.state = {perfil: localStorage.perfil}
    this.state = {isLoggedIn: localStorage.isLoggedIn}
    //console.log("LOCALSTORAGE", localStorage)
  }
  
  loggedHandler = function(transition) {
    if( this.state.token === ""  ){
      window.location = "/"

    }
  };


  render () {
  
    return (
      <Suspense fallback={<Spinner/>}>
       
        <Switch>
          <Route path="/dashboard" component={ Dashboard } onEnter={ this.loggedHandler() } />
          <Route path="/continente" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/dashboard/continente/cntnorte" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/dashboard/continente/cntcentro" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/dashboard/continente/cntsul" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/modelo" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/dashboard/modelo/cmnorte" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/dashboard/modelo/cmcentro" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/dashboard/modelo/cmsul" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/bomdia" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/dashboard/bomdia/cbdnorte" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/dashboard/bomdia/cbdcentro" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/dashboard/bomdia/cbdsul" component={ Dashboard }  onEnter={ this.loggedHandler() }/>
          <Route path="/piloto" component={ Dashboard }  onEnter={ this.loggedHandler() }/>

          <Route path="/tables/basic-table" component={ BasicTable } />
          <Route path="/profile" component={ Profile }  />
          <Route path="/inbox" component={ Inbox }  />
          <Route path="/metrics" component={ MetricsNew }  />
          <Route path="/metrics-new" component={MetricsNew} />
          <Route path="/system" component={System} />
          
          <Route path="/form-Elements/basic-elements" component={ BasicElements } />

          <Route path="/Loja/loja" component={ Loja } onEnter={ this.loggedHandler() } />
          <Route path="/Unidades/unidade" component={ Unidade } />

          <Route path="/Suporte" component={ Seccao } onEnter={ this.loggedHandler() }/>
          <Route path="/Caixas" component={ Seccao } onEnter={ this.loggedHandler() }/>
          <Route path="/Têxtil" component={ Seccao } onEnter={ this.loggedHandler() } />
          <Route path="/Frescos" component={ Seccao } onEnter={ this.loggedHandler() }/>
          <Route path="/Fluxos" component={ Seccao } onEnter={ this.loggedHandler() }/>
          <Route path="/Cafetaria" component={ Seccao } onEnter={ this.loggedHandler() }/>
          <Route path="/backoffice" component={ Backoffice }  />


          <Route exact path="/login" component={ Login } />
          <Route path="/welcome" component={ Welcome } onEnter={ this.loggedHandler() } />
          <Route path="/user-pages/register" component={ Register1 } />

          <Route path="/Historico" component={ Historico } />
          <Route path="/Risco" component={ HistoricoNew} />
          <Route path="/historico-new" component={HistoricoNew}/>

          <Route path="/users" component={ Users } />
          <Route path="/unidade" component={ Unidade } />

          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } />


          <Route path="/send" component={ Form }  />

          <Redirect to="/" />
        </Switch>
      </Suspense>
    );
  }
}

export default withRouter(AppRoutes);